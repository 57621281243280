import axios from "axios";

export const state = {
  items: [],
  item: {}
};

export const getters = {
  getAllData: state => state.items,
  getItem: state => state.item
};

export const mutations = {
  SET_ALL_DATA(state, about) {
    state.items = about.data.map(obj => {
      return {
        aboutId: obj.aboutId,
        name: obj.name,
        slug: obj.about.slug
      };
    });
  },

  SET_ITEM(state, about) {
    state.item = about;
  }
};

export const actions = {
  async getAllAboutData({ commit }, params) {
    const { data } = await axios.get(`/about`, { params });

    commit("SET_ALL_DATA", data);

    return data;
  },

  async getAboutBySlug({ commit }, params) {
    const { lng, id } = params;

    const { data } = await axios.get(`/about/id/${id}`, {
      params: { lng }
    });

    commit("SET_ITEM", data);

    return data;
  },

  async updateById({ commit }, params) {
    const { id, lng, value } = params;

    const { data } = await axios.put(`/about/${id}`, { value, lng });

    commit("SET_ITEM", data);

    return data;
  }
};

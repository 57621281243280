<template>
  <div id="app">
    <layout v-if="layout === 'default'">
      <router-view :key="$route.fullPath" />
    </layout>
    <auth-layout v-if="layout === 'auth'">
      <router-view :key="$route.fullPath" />
    </auth-layout>
    <error-layout v-if="layout === 'error'">
      <router-view :key="$route.fullPath" />
    </error-layout>
  </div>
</template>

<script>
import Layout from "./layouts/main";
import AuthLayout from "@/layouts/auth";
import ErrorLayout from "@/layouts/error";

export default {
  page: {
    // // All subcomponent titles will be injected into this template.
    // titleTemplate(title) {
    //     title = typeof title === 'function' ? title(this.$store) : title;
    //     return title ? `${title} | ${appConfig.title}` : appConfig.title;
    // },
    // meta: [{ name: 'description', content: appConfig.description }]
  },
  components: {
    Layout,
    AuthLayout,
    ErrorLayout
  },

  data() {
    return {
      layout: "default",
      authRoutes: ["login", "reset-password", "forget-password"]
    };
  },
  watch: {
    "$route.fullPath"() {
      let route = this.$route.fullPath.split("/")[1];
      route = route.split("?")[0];
      this.layout = this.$route.meta.layout;
    }
  }
};
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss"></style>

<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/logo.jpeg')"
          max-height="50px"
          max-width="100%"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        />
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" to="/" icon="mdi-home-outline" />

      <nav-menu-group title="Pages" icon="mdi-file">
        <nav-menu-link
          title="Home"
          to="/pages/home-page"
          icon="mdi-home-outline"
        />
        <nav-menu-link
          title="About"
          to="/pages/about"
          icon="mdi-information-outline"
        />
        <nav-menu-link
          title="Product Categories"
          to="/pages/categories"
          icon="mdi-dots-horizontal"
        />
        <nav-menu-link
          title="Products"
          to="/pages/products"
          icon="mdi-cube-send"
        />
        <nav-menu-link
          title="Resources"
          to="/pages/resources"
          icon="mdi-paperclip"
        />
      </nav-menu-group>
      <nav-menu-link
        title="File manager"
        to="/file-manager"
        icon="mdi-file-image"
      />
      <!-- <nav-menu-section-title title="USER INTERFACE" />
      <nav-menu-link title="Typography" to="/typography" icon="mdi-alpha" /> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavMenuSectionTitle from "@/components/navbar/NavMenuSectionTitle";
import NavMenuGroup from "@/components/navbar/NavMenuGroup";
import NavMenuLink from "@/components/navbar/NavMenuLink";

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/state/store";

Vue.use(VueRouter);

const pages = [
  {
    path: "/pages/home-page",
    name: "home-page",
    component: () => lazyLoadView(import("@/views/pages/Home")),
    meta: { layout: "default", authRequired: true }
  },

  {
    path: "/pages/about",
    name: "about-page",
    component: () => lazyLoadView(import("@/views/pages/about/Index")),
    meta: { layout: "default", authRequired: true },
    children: [
      {
        path: "/pages/about/:slug/:id",
        name: "organization-page1",
        component: () => lazyLoadView(import("@/views/pages/about/Edit")),
        meta: { layout: "default", authRequired: true }
      },
      {
        path: "/pages/about/main",
        name: "organization-page",
        component: () => lazyLoadView(import("@/views/pages/about/Main")),
        meta: { layout: "default", authRequired: true }
      }
    ]
  },

  {
    path: "/pages/categories",
    name: "product-categories-page",
    component: () => lazyLoadView(import("@/views/pages/categories/Index")),
    meta: { layout: "default", authRequired: true },
    children: [
      {
        path: "/pages/categories/:slug/:id",
        name: "products-categorie-page",
        component: () => lazyLoadView(import("@/views/pages/categories/Edit")),
        meta: { layout: "default", authRequired: true }
      },

      {
        path: "/pages/categories/main",
        name: "products-main-page",
        component: () => lazyLoadView(import("@/views/pages/categories/Main")),
        meta: { layout: "default", authRequired: true }
      }
    ]
  },

  {
    path: "/pages/products",
    name: "product-page",
    component: () => lazyLoadView(import("@/views/pages/products")),
    meta: { layout: "default", authRequired: true },
    children: [
      {
        path: "/pages/products/:slug/:id",
        name: "products-page",
        component: () => lazyLoadView(import("@/views/pages/products/Edit")),
        meta: { layout: "default", authRequired: true }
      }
    ]
  },

  {
    path: "/pages/resources",
    name: "resources-page",
    component: () => lazyLoadView(import("@/views/pages/Resources")),
    meta: { layout: "default", authRequired: true }
  }
];

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => lazyLoadView(import("@/views/auth/Login")),

    meta: {
      layout: "auth",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "home" });
        } else {
          next();
        }
      }
    }
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logout");
        next({ path: "/" });
      }
    }
  },

  {
    path: "/reset-password",
    name: "forgot-password",
    component: () => lazyLoadView(import("@/views/auth/ForgetPassword")),
    meta: { layout: "auth" }
  },

  {
    path: "/reset-password/:code",
    name: "reset-password",
    component: () => lazyLoadView(import("@/views/auth/ForgetPassword")),
    meta: { layout: "auth" }
  },

  {
    path: "/",
    name: "home",
    component: () => lazyLoadView(import("@/views/Home")),
    meta: { layout: "default", authRequired: true }
  },

  ...pages,

  {
    path: "/*",
    name: "error",
    component: () => lazyLoadView(import("@/views/NotFound")),
    meta: {
      layout: "error",
      authRequired: false
    }
  }
];

setInterval(() => {
  if (store.getters["auth/loggedIn"]) {
    store.dispatch("auth/refreshToken");
    store.dispatch("auth/validate");
  }
}, 600000);

const router = new VueRouter({
  routes,
  base: "/",
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    delay: 400,
    timeout: 10000
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children);
    }
  });
}

router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some(route => route.meta.authRequired);

  if (routeTo.name === "login") {
    return next();
  }

  if (!authRequired) return next();

  if (store.getters["auth/loggedIn"]) {
    store.dispatch("auth/validate");
    return next();
  } else {
    next("/login");
  }
});

export default router;

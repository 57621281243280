import axios from "axios";

export const state = {
  currentUser: getSavedState("user"),
  refreshToken: getSavedState("refreshToken"),
  token: getSavedState("token"),
  defaultLanguage: getSavedState("defaultLanguage"),
  loading: false
};

export const getters = {
         loggedIn(state) {
           return (
             state.currentUser &&
             Object.keys(state.currentUser).length > 0 &&
             Object.getPrototypeOf(state.currentUser) === Object.prototype
           );
         },

         currentUser: state => state.currentUser,
         defaultLanguage: state => {
           return state.defaultLanguage
             ? state.defaultLanguage
             : { id: 0, title: "English", lng: "en" };
         }
       };

export const mutations = {
  SET_DEFAULT_LANGUAGE(state, value) {
    saveState("defaultLanguage", value);
    state.defaultLanguage = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_CURRENT_USER(state, data) {
    saveState("defaultLanguage", { id: 0, title: "English", lng: "en" });
    saveState("refreshToken", data.refreshToken);
    saveState("user", data.user);
    saveState("token", data.token);
    state.currentUser = data.user;
  },

  UPDATE_USER(state, data) {
    saveState("user", data);
  },

  LOGOUT(state, newValue) {
    localStorage.user = JSON.stringify({});
    localStorage.token = JSON.stringify({});
  }
};

export const actions = {
  init({ state, dispatch }) {
    dispatch("validate");
  },

  async logout({ commit }) {
    commit("LOGOUT", null);
  },

  async login({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) {
      return dispatch("validate");
    }

    try {
      const { data } = await axios.post("/login", {
        email,
        password
      });

      commit("SET_CURRENT_USER", data);

      return data;
    } catch (error) {
      console.error(error);

      throw error;
    }
  },

  async validate({ commit, state }) {
    if (localStorage.token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.token);
    }

    try {
      const { data } = await axios.get("/me");

      return data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        commit("SET_CURRENT_USER", null);
      }

      return null;
    }
  },

  async recoverPassword({ commit, state }, { email }) {
    await axios.post("/recover-password", { email });
  },

  async resetPassword({ commit, state }, { token, password, passwordRepeat }) {
    await axios.post(`/recover-password/${token}`, {
      password,
      passwordRepeat
    });
  },

  async updateUser({ commit, state }, params) {
    const { id, firstName, lastName, email } = params;

    const { data } = await axios.put(`/users/${id}`, {
      firstName,
      lastName,
      email
    });

    if (data) {
      commit("UPDATE_USER", data);
    }
  }
};

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

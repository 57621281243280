import Vue from "vue";
import app from "./app.vue";
import router from "./router";
import store from "./state/store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VueToast from "vue-toast-notification";
import "./config/axios.config.js";

import "@/styles/styles.scss";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueToast);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(app)
}).$mount("#app");

<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen" />

    <v-app-bar color="blue" elevation="4" flat outlined>
      <div class="d-flex justify-space-between px-5" style="width:100%">
        <v-btn
          @click="
            () => {
              isDrawerOpen = !isDrawerOpen;
            }
          "
          text
        >
          <v-icon color="white">mdi-menu</v-icon>
        </v-btn>
        <app-bar-user-menu v-if="isLogged" />
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot />
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0" />
  </v-app>
</template>

<script>
import VerticalNavMenu from "@/components/navbar/VerticalNavMenu";
import AppBarUserMenu from "@/components/navbar/AppBarUserMenu";
import { mapGetters } from "vuex";

export default {
  components: {
    VerticalNavMenu,
    AppBarUserMenu
  },

  data() {
    return {
      isDrawerOpen: true
    };
  },

  computed: {
    ...mapGetters({
      isLogged: "auth/loggedIn"
    })
  }
};
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}
</style>
